<template>
	<div :class="{ 'processing':loading }" style="min-height: 300px;">
		<div class="tabs-wrapper" v-show="!loading && devices.length">
			<ul class="tabs">
				<li class="tabs__item" v-for="(item, index) in tabs" :key="index">
					<a class="nav-tab swith-content-btn " @click="switchTab(item.type)" :class="isActiveTab(item.type)">
						{{ item.title }}
					</a>
				</li>
			</ul> 
		</div>
		<div class="tab-content" v-if="!loading && devices.length">
			<div class="tab-content__item" :class="isActiveTab(tabs[0].type)">
				<DashboardTab ></DashboardTab>
			</div>
			<div class="tab-content__item" :class="isActiveTab(tabs[1].type)">
				<TableAdvanced v-model="sortCol" :rows="filteredRows" :columns="columns" :isCheckedAll="isCheckedAll" :checkedInputs="checkedInputs" @toggleAllCheckedInputs="toggleAllCheckedInputs">
					<template v-slot:filters>
						<div class="filter-options__search">
							<input type="search" 
								class="input bg-left search-icon" 
								placeholder="Search" 
								v-model="rowsFilters.search"
							/>
						</div>
						<div class="filter-options__items flex-x-wrap flex-auto">
							<!-- <DropdownAdvanced
								:options="deviceCategories"
								v-model="rowsFilters.category"
							/>
							<DropdownAdvanced
								:options="locationOptions"
								v-model="rowsFilters.location"
							/>
							<DropdownAdvanced
								:options="statusOptions"
								v-model="rowsFilters.status"
							/> -->
							<DropdownAdvanced
								:options="deviceTypes"
								v-model="rowsFilters.device_type"
							/>
						</div>
					</template>

					<template v-slot:row="slotData">
						<td>
							<label class="module__check">
								<input type="checkbox" name="category" :checked="checkedInputs[slotData.r.id]" @change="setCheckedInputs($event,slotData.r.id)"/>
								<span class="check"></span>
								<router-link :to="`/devices/${slotData.r.id}`">
									<span class="text">{{ slotData.r.name }}</span>
								</router-link>
							</label>
						</td>
						<td>
							<div class="flex icon-text icon-sm">
								<DeviceIcon :category="slotData.r.category" />
								<span>{{ slotData.r.category }}</span>
							</div>
						</td>
						<td> {{ slotData.r.date_of_purchase }}</td>
						<td> {{ slotData.r.device_type }} </td>
						<td>
							<div class="flex icon-text">
								<inline-svg :src="require(`@/assets/img/Pin-Location.svg`)"/>
								<span>{{ slotData.r.address }}</span>
							</div>
						</td>
						<td>{{ slotData.r.device_info }}</td>
						<td style="padding-right: 50px">
							<span class="table-statys" :class="slotData.r.status">
								<span class="dot"></span> {{ slotData.r.status }}
							</span>
						</td>
						<td class="position-stiky" v-click-outside="closeOptions">
							<div class="table-options-drop dropdown-wrapper">
								<button
									type="button"
									class="btn btn-sm btn-transp dropdown-btn"
									@click="openOptions"
									
								>
									<inline-svg :src="require(`@/assets/img/dots-menu.svg`)"/>
								</button>
								<div class="categories__dropdown dropdown-content">
									<ul>
										<li>
											<router-link :to="`/devices/${slotData.r.id}?tab=info`">{{ $translate('edit') }}</router-link>
										</li>
										<li>
											<router-link :to="`/devices/${slotData.r.id}`">{{ $translate('view-device') }}</router-link>
										</li>
										<li>
											<router-link :to="`/devices/${slotData.r.id}?tab=rules`">{{ $translate('set-rules') }}</router-link>
										</li>
										<li>
											<router-link :to="`/devices/${slotData.r.id}?tab=schedule`">{{ $translate('schedule') }}</router-link>
										</li>
										<li>
											<router-link :to="`/devices/${slotData.r.id}?tab=report`">{{ $translate('report') }}</router-link>
										</li>
										<li class="list-line"></li>
										<li>
											<button type="button" class="red-color" @click="confirmDeleteOpen(slotData.r.id)">
												{{ $translate('delete-device') }}
											</button>
										</li>
									</ul>
								</div>
							</div>
						</td>
					</template>	
				</TableAdvanced>
			</div>
			<div class="tab-content__item" :class="isActiveTab(tabs[2].type)" v-if="activeTab === tabs[2].type">
				<PaddocksMap :paddocks="paddocksWithDevices"/>
			</div>
		</div>
		<div v-else class="tab-content">
			<div class="tab-content__item active" v-show="!loading">
				<h2 style="padding-top: 20px;">{{ $translate('devices-not-found') }}</h2>
			</div>
		</div>
	</div>
</template>


<script>
import Base from "@/components/base";
import Popups from "@/components/popups";
import { API } from 'aws-amplify';
import { mixTables } from '@/mixins';
import { reportCategoriesList } from '@/config/farm';
import DashboardTab from "@/views/reports/inners/tabs/DashboardTab";
import PaddocksMap from '@/views/reports/inners/map-zone-details/PaddocksMap';
import { devTypes } from '@/config';

export default {
    components: {
        ...Base,
		...Popups,
		DashboardTab,
		PaddocksMap,
    },
	mixins:[mixTables, ],
    data() {
        return {
			loading: true,
			tabs: [
				{
					title: 'IOT dashboard',
					type: 'iot',
				},
				{
					title: 'Device List',
					type: 'device-list',
				},
				{
					title: 'Map',
					type: 'Map',
				},
			],

			source: 'devices',
			columns: [
				{ name: 'Device Name', filter: 'name' },
				{ name: 'Category', filter: 'category' },
				{ name: 'Date of Purchase', filter: 'date' },
				{ name: 'Type', filter: 'device_type' },
				{ name: 'Location', filter: 'address' },
				{ name: 'Device Info', filter: 'device_info' },
				{ name: 'Status', filter: 'status', colspan: 2 }
			],
			calculatedFields:[{
				filter: 'address',
				path: ['location'],
				calcFunction: (location)=>location.address 
			}],
        };
    },
    computed: {
		activeTab: {
			get() {
				return this.$route.query.tab || this.tabs[0].type
			},
			set(tab) {
				this.$router.push({ query: { tab }})
			}
		},
		isActiveTab() {
			return tab => tab === this.activeTab ? 'active' : ''
		},
		devices(){
			return this.$store.state.devices;
		},
		paddocks(){
			return this.$store.state.paddocks;
		},
		paddocksWithDevices(){
			return this.$store.state.paddocks.filter(item => this.devices.map(item2 => item2.paddockID).includes(item.id))
		},
		deviceTypes() {
			return devTypes
		}
	},

    methods: {
		switchTab(tab) {
            this.activeTab = tab;
        },
		openOptions(ev) {
            ev.target.classList.toggle("active");
			ev.target.nextElementSibling.classList.toggle('active');
        },
		closeOptions(){
			let dropdownButton=document.querySelector('.table-options-drop.dropdown-wrapper .btn.btn-sm.btn-transp.dropdown-btn.active')
			let dropdownContent=document.querySelector('.table-options-drop.dropdown-wrapper .categories__dropdown.dropdown-content.active')
			if(dropdownButton&&dropdownContent){
				dropdownButton.classList.remove("active")
				dropdownContent.classList.remove("active")
			}
		},
    },
	async created(){
		let farm = await this.$store.dispatch('GET_FARM');
		if(!farm){
			await API.graphql({
				query: createFarm,
				variables: {
					input: {
						companyID: this.$store.state.companyData.id
					}
				}
			});

			await this.$store.dispatch('GET_FARM');
		}
		await this.$store.dispatch('GET_PADDOCKS');
		await this.$store.dispatch('GET_DEVICES');
		await this.$store.dispatch('GET_FARMACTIONS');

		this.loading = false;
	}
};
</script>
