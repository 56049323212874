<template>
<div :class="{ 'processing':loading }" style="min-height: 300px;">
	<div class="tabs-wrapper">
		<ul class="tabs" :style="{ opacity: loading ? 0 : 1 }">
			<template v-for="(item, index) in tabs">
				<li class="tabs__item" v-show="item.show" :key="index">
					<a class="nav-tab swith-content-btn " @click="switchTab(item.type)" :class="isActiveTab(item.type)">
						{{ item.title }}
					</a>
				</li>
			</template>
		</ul> 
	</div>
	<div class="tab-content" :key="updateKey" v-if="!loading">
		<div class="tab-content__item" :class="isActiveTab(tabs[0].type)">
			<DetailsList :singleZone="singleZone" @selectSingleZone="selectSingleZone($event)" />
		</div>
		<div class="tab-content__item" :class="isActiveTab(tabs[1].type)" v-if="activeTab === tabs[1].type">
			<PaddocksMap :paddocks="filteredByCategoryPaddocks"/>
		</div>
		<div class="tab-content__item" :class="isActiveTab(tabs[2].type)">
			<DashboardTab :devicesList="devices.map(item => item.id)" />
		</div>
		<div class="tab-content__item" :class="isActiveTab(tabs[3].type)">
			<div class="addition" style="padding-top: 20px;">
				<div class="addition__header">
					<div class="headline headline--sm">
						<h2 class="headline__title">{{ $translate('related-herds') }}</h2>
					</div>
				</div>
				<ul class="editable-list">
					<li class="editable-list__item" v-for="item in relatedHerds" :key="item.id">
						<div class="data-row">
							<ul class="data-row__list">
								<li class="data-row__item">
									<a @click="$router.push({ path: '/farm-map', query: { 'edit': item.id } });">{{ item.basic_info.name || '-' }}</a>
								</li>
								<li class="data-row__item">{{ item.basic_info.number_of_animals || '-' }}</li>
								<li class="data-row__item">{{ item.basic_info.type || '-' }}</li>
								<li class="data-row__item">{{ item.basic_info.date_of_birth || '-' }}</li>
							</ul>
						</div>
					</li>
					<li v-if="!relatedHerds.length">
						{{ $translate('related-herds') }} {{ $translate('not-found') }}
					</li>
				</ul>
			</div>
		</div>
		<div class="tab-content__item active" v-if="!paddockList.length" :class="isActiveTab('empty')">
			<h2 style="padding-top: 20px;">{{ reportCategoriesList.find(item => item.category === this.$route.params.category).title }} {{ $translate('not-found') }}</h2>
		</div>
	</div>

	<FarmListDownload v-if="downloadList" @close="closeDownloadList" @confirm="confirmDownloadList" />
</div>
</template>


<script>
import Base from "@/components/base";
import Popups from "@/components/popups";
import { API } from 'aws-amplify';
import { mixTables } from '@/mixins';
import { reportCategoriesList } from '@/config/farm';
import DetailsList from './DetailsList';
import PaddocksMap from './PaddocksMap';
import DashboardTab from "@/views/reports/inners/tabs/DashboardTab"

export default {
    name: "MapZoneDetailsReport",
    components: {
        ...Base,
		...Popups,
		DetailsList,
		PaddocksMap,
		DashboardTab,
    },
	mixins:[mixTables, ],
    data() {
        return {
			reportCategoriesList: reportCategoriesList,
			downloadList: false,
			loading: true,
			tabs: [
				{
					title: 'List of fields',
					type: 'list',
					show: true,
				},
				{
					title: 'Map',
					type: 'map',
					show: true,
				},
				{
					title: 'IOT dashboard',
					type: 'iot',
					show: true,
				},
				{
					title: 'Related herds',
					type: 'related-herds',
					show: true,
				},
			],
			singleZone: null,
			updateKey: 0,
        };
    },
    computed: {
		activeTab: {
			get() {
				return this.$route.query.tab || this.tabs[0].type
			},
			set(tab) {
				this.$router.push({ query: { tab }})
			}
		},
		isActiveTab() {
			return tab => tab === this.activeTab ? 'active' : ''
		},
		paddockList(){
			if(this.singleZone){
				return this.$store.state.paddocks.filter(item => item.id === this.singleZone);
			} else {
				return this.$store.state.paddocks;
			}
        },
		devices(){
            return this.$store.state.devices.filter(item => this.filteredByCategoryPaddocks.map(item2 => item2.id).includes(item.paddockID));
        },
		filteredByCategoryPaddocks(){
			return this.paddockList.filter(item => (item.type === this.$route.params.category));
		},
		relatedHerds(){
			return this.$store.state.paddocks.filter(item => item.type === 'herd' && item.child_paddock && this.filteredByCategoryPaddocks.map(item2 => item2.id).includes(item.child_paddock))
		},
	},

    methods: {
        switchTab(tab) {
            this.activeTab = tab;
        },
		openDownloadList() {
            this.downloadList = true;
        },
        closeDownloadList() {
            this.downloadList = false;
        },
        confirmDownloadList(format) {
            console.log(format);
            this.closeDownloadList();
        },
		selectSingleZone(zone){
			if(this.singleZone === zone){
				this.singleZone = null;
			} else {
				this.singleZone = zone;
			}

			this.updateKey++;
		}
    },
	async created(){
		this.tabs.forEach(element => {
			element.show = true;
		});

		let farm = await this.$store.dispatch('GET_FARM');
		if(!farm){
			await API.graphql({
				query: createFarm,
				variables: {
					input: {
						companyID: this.$store.state.companyData.id
					}
				}
			});

			await this.$store.dispatch('GET_FARM');
		}
		await this.$store.dispatch('GET_PADDOCKS');
		await this.$store.dispatch('GET_DEVICES');

		if(!this.devices.length){
			this.tabs[2].show = false;
		}

		if(!this.paddockList.length){
			this.tabs.forEach(element => {
				element.show = false;
			});
			this.activeTab = 'empty';
		}

		this.loading = false;
	}
};
</script>