<template>
	<div>
		<div style="padding-top: 15px;">
			<div class="content__header flex-md-col flex-lg-col">
				<div class="content__header-left">
					<h1 class="heading-page-h1">{{ $translate('iot-dashboard') }}</h1>

					<DropdownAdvanced
						:options="deviceCategories"
						v-model="filters.category"
					/>

					<form class="form-search">
						<button type="submit" class="btn-seach">
							<img src="@/assets/img/search-loupe.svg">
						</button>
						<input type="search" class="input" placeholder="Text" v-model="filters.search">
						<a class="btn-setings btn" @click="openFilters">
							<img src="@/assets/img/Settings-seach.svg">
						</a>
					</form>
				</div>
			
			</div>
			<div class="content__body">
				<div class="cards flex" v-if="filteredDevices && filteredDevices.length">
					<div class="cards__col-3" v-for="d in filteredDevices" :key="d.id">
						<Card :device="d" @toggle="toggleDevice($event, d.id)" />
					</div>
				</div>
				<div class="no-data" v-else>
					<p>{{ $translate('no-devices-found') }}</p>
				</div>
			</div>
		</div>

		<div class="popup_wrpr" v-if="filters.show">
			<div class="popup_overlay" @click="closeFilters"></div>
			<div class="popup__window">
				<div class="popup__window-content">
					<div class="popup__window-header">
						<h2>{{ $translate('search-settings') }}</h2>
						<button type="button" class="popup__closer" @click="closeFilters">
							<inline-svg :src="require('@/assets/img/close-icon.svg')"/>
						</button>
					</div>
					<div class="popup__window-body">
						<form>
							<div class="items-group cards">
								<div class="cards__col-6">
									<div class="el-form mt-15">
										<label class="el-form__title">{{ $translate('location') }}</label>
										<DropdownAdvanced 
											:options="locationOptions"
											v-model="filters.location"
										/>
									</div>
								</div>
								<div class="cards__col-6">
									<div class="el-form mt-15">
										<label class="el-form__title">{{ $translate('groups') }}</label>
										<DropdownAdvanced 
											:options="groupOptions"
											v-model="filters.group"
										/>
									</div>
								</div>
								<div class="cards__col-6">
									<div class="el-form mt-15">
										<label class="el-form__title">{{ $translate('status') }}</label>
										<DropdownAdvanced 
											:options="statusOptions"
											v-model="filters.status"
										/>
									</div>
								</div>
							</div>
							<div class="items-group cards">
							
								<div class="cards__col-6">
									<div class="el-form mt-15">
										<label class="el-form__title">{{ $translate('date-from') }}</label>
										<DatePicker v-model="filters.start"/>

									</div>
								</div>
								<div class="cards__col-6">
									<div class="el-form mt-15">
										<label class="el-form__title">{{ $translate('date-to') }}</label>
										<DatePicker v-model="filters.end"/>

									</div>
								</div>
							</div>
							<div class="action">
								<button type="button" class="btn btn-primary btn-standart" @click="resetFilters">
									<span>{{ $translate('reset-all-filters') }}</span>
								</button>
								<button type="button" class="btn btn-primary btn-standart" @click="submitFilters">
									<span>{{ $translate('apply') }}</span>
								</button>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>

	</div>	
</template>

<script>
import { API } from 'aws-amplify';

import { 
	createDevice,
	updateDevice
} from '@/graphql/mutations';

import Base from '@/components/base';
import Popups from '@/components/popups';

import { devCats, devStatus } from '@/config';

export default {
	name: 'IOTDashboard',
	components: {
		...Base,
		...Popups
	},
	props:['devicesList'],
	data() {
		return {
			addDevicePopup: false,
			filters: {
				show: false,
				category: 'all',
				group: 'all',
				status: 'all',
				location: 'all',
				search: '',
				start: null,
				end: null,
			}
		}
	},

	computed: {
		userData() {
			return this.$store.state.userData || {}
		},
		companyData() {
			return this.$store.state.companyData || {}
		},
		devices() {
				return this.$store.state.devices || []
		},
		filteredDevices() {
			let devices = this.devices
			if(this.devicesList){
				devices=devices.filter(d=>{
					return this.devicesList.includes(d.id)
				})
			}
			return devices.filter(r => {
				let result = true;
				if(this.filters.category && this.filters.category !== 'all') result = r.category === this.filters.category;
				if(this.filters.group && this.filters.group !== 'all') result = r.group === this.filters.group;
				if(this.filters.status && this.filters.status !== 'all') result = r.status === this.filters.status;
				if(this.filters.location && this.filters.location !== 'all') result = r.location === this.filters.location;
				if(this.filters.search.length) result = r.name.toLowerCase().indexOf(this.filters.search.toLowerCase()) !== -1;
				//if(this.filters.start)
				return result
			})
		},
		deviceCategories() {
			return [ { id: 'all', name: 'All Categories' } , ...devCats]
		},
		statusOptions() {
			return [ { id: 'all', name: 'All' } ,
				...Object.keys(devStatus)
					.filter(opt => opt !== 'true' && opt !== 'false' )
					.map(o => ({ id: o, name: o }))
				]
		},
		locationOptions() {
			return [{ id: 'all', name: 'All Locations' }]
		},
		groupOptions() {
			return [{ id: 'all', name: 'All Groups' }]
		}
	},

	methods: {
		changeFiltersCategory(ev) {
			const { name, value } = ev.target;
			this.filters[name] = value.id;
		},
		openFilters() {
			this.filters.show = true
		},
		closeFilters() {
			this.filters.show = false
		},
		resetFilters() {
			this.filters = {
				show: false,
				category: 'all',
				group: 'all',
				status: 'all',
				location: 'all',
				search: '',
				start: null,
				end: null,
			}
		},
		submitFilters(ev) {
			//this.filters = ev;
			this.closeFilters();
		},
	
		async toggleDevice(ev, deviceID) {
			const input = {
				id: deviceID,
				status: ev
			}
			const device = await API.graphql({
				query: updateDevice,
				variables: { input }
			}).then(res => res.data.updateDevice);

			this.$store.commit('UPDATE_ITEM', { field: 'devices', item: device })	
		}
	}



	
}
</script>
