<template>
<div class="field_logbook_page" :class="{ 'processing':loading }">
	<div class="relative">
		<button type="button" class="btn btn-sm btn-w-shadow size-40-40" style="position: absolute;right: 0;top: -57px;" @click="openDownloadList">
			<inline-svg :src="require(`@/assets/img/ico-download.svg`)"/>
		</button>
		<a class="btn btn-w-shadow btn-sm btn-back size-40-40" style="position: absolute;right: 50px;top: -57px;" @click="changeLogbookView" v-if="!singleAction && !singleActionData">
			<!-- <inline-svg :src="require(`@/assets/img/Arrow-prev.svg`)"/> -->
			<svg fill="none" viewBox="0 0 24 24" height="24" width="24" xmlns="http://www.w3.org/2000/svg" v-if="logbookView === 'calendar'">
				<path xmlns="http://www.w3.org/2000/svg" d="M8 13H16" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
				<path xmlns="http://www.w3.org/2000/svg" d="M8 17H16" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
				<path xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd" d="M6 3H15.172C15.702 3 16.211 3.211 16.586 3.586L19.414 6.414C19.789 6.789 20 7.298 20 7.828V19C20 20.105 19.105 21 18 21H6C4.895 21 4 20.105 4 19V5C4 3.895 4.895 3 6 3Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
				<path xmlns="http://www.w3.org/2000/svg" d="M20 8H16C15.448 8 15 7.552 15 7V3" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
				<path xmlns="http://www.w3.org/2000/svg" d="M8 9H11" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
			</svg>
			<svg fill="none" viewBox="0 0 24 24" height="24" width="24" xmlns="http://www.w3.org/2000/svg" v-if="logbookView === 'list'">
				<path xmlns="http://www.w3.org/2000/svg" d="M16 2V6" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
				<path xmlns="http://www.w3.org/2000/svg" d="M8 2V6" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
				<path xmlns="http://www.w3.org/2000/svg" d="M3 9H21" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
				<path xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd" d="M19 4H5C3.895 4 3 4.895 3 6V19C3 20.105 3.895 21 5 21H19C20.105 21 21 20.105 21 19V6C21 4.895 20.105 4 19 4Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
				<path xmlns="http://www.w3.org/2000/svg" d="M7.01268 12.729C6.87468 12.729 6.76268 12.841 6.76368 12.979C6.76368 13.117 6.87568 13.229 7.01368 13.229C7.15168 13.229 7.26368 13.117 7.26368 12.979C7.26368 12.841 7.15168 12.729 7.01268 12.729" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
				<path xmlns="http://www.w3.org/2000/svg" d="M12.0127 12.729C11.8747 12.729 11.7627 12.841 11.7637 12.979C11.7637 13.117 11.8757 13.229 12.0137 13.229C12.1517 13.229 12.2637 13.117 12.2637 12.979C12.2637 12.841 12.1517 12.729 12.0127 12.729" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
				<path xmlns="http://www.w3.org/2000/svg" d="M17.0127 12.729C16.8747 12.729 16.7627 12.841 16.7637 12.979C16.7637 13.117 16.8757 13.229 17.0137 13.229C17.1517 13.229 17.2637 13.117 17.2637 12.979C17.2637 12.841 17.1517 12.729 17.0127 12.729" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
				<path xmlns="http://www.w3.org/2000/svg" d="M7.01268 16.729C6.87468 16.729 6.76268 16.841 6.76368 16.979C6.76368 17.117 6.87568 17.229 7.01368 17.229C7.15168 17.229 7.26368 17.117 7.26368 16.979C7.26368 16.841 7.15168 16.729 7.01268 16.729" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
				<path xmlns="http://www.w3.org/2000/svg" d="M12.0127 16.729C11.8747 16.729 11.7627 16.841 11.7637 16.979C11.7637 17.117 11.8757 17.229 12.0137 17.229C12.1517 17.229 12.2637 17.117 12.2637 16.979C12.2637 16.841 12.1517 16.729 12.0127 16.729" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
			</svg>
		</a>
		<template v-if="!singleAction && !singleActionData">
			<ActionsList :innerTab="true" :fieldBookList="true" @initedActions="actions = $event" @singleActionChange="singleActionChange($event)" v-if="logbookView === 'list'" />
			<CalendarActionsView v-if="logbookView === 'calendar' && farmActions.length" @singleActionChange="singleActionChange($event)" @initedActions="actions = $event" />
		</template>
	</div>
	<div v-if="singleAction && singleActionData">
		<div class="tabs-wrapper">
			<ul class="tabs">
				<li class="tabs__item">
					<a class="nav-tab swith-content-btn" @click="switchTab('basic_info')" :class="isActiveTab('basic_info')">{{ $translate('basic-info') }}</a>
				</li>
				<li class="tabs__item" v-if="singleActionData.related && singleActionData.related.length">
					<a class="nav-tab swith-content-btn" @click="switchTab('related_fields')" :class="isActiveTab('related_fields')">{{ $translate('related-fields') }}</a>
				</li>
				<li class="tabs__item" v-if="singleActionData.related && singleActionData.related.length">
					<a class="nav-tab swith-content-btn" @click="switchTab('location')" :class="isActiveTab('location')">{{ $translate('location') }}</a>
				</li>
				<li class="tabs__item" v-if="companyData">
					<a class="nav-tab swith-content-btn" @click="switchTab('company_info')" :class="isActiveTab('company_info')">{{ $translate('company-info') }}</a>
				</li>
			</ul> 
		</div>
		<div class="tab-content" v-if="!loading" style="padding-top: 15px;">
			<a @click="singleActionChange(null)" class="btn btn-w-shadow btn-sm btn-back">
				<inline-svg :src="require(`@/assets/img/Arrow-prev.svg`)"/> {{ $translate('back-to-log-book-list') }}
			</a>
			<div class="tab-content__item" :class="isActiveTab('basic_info')">
				<template v-for="(basicInfoItem, bInfoIndex) in actionBasicInfoConfig[singleActionData.category]">
					<div 
						:class="[ basicInfoItem.checkboxes || basicInfoItem.textarea ? 'form-group__col-10' : 'form-group__col-5 mb-15', { 'mb-15':basicInfoItem.textarea } ]" 
						:key="bInfoIndex" v-if="!basicInfoItem.time" style="padding-top: 15px;"
					>
						<div class="el-form">
							<div class="headline headline--sm" v-if="basicInfoItem.name && basicInfoItem.headlineTitle">
								<h2 class="headline__title">{{ basicInfoItem.name }}</h2>
							</div>
							<label class="el-form__title" v-if="basicInfoItem.name && !basicInfoItem.headlineTitle" style="color: #9DB0B7;">{{ basicInfoItem.name }}</label>

							<div class="form-group__row form-group__row--sm" v-if="basicInfoItem.datepicker">
								<div class="form-group__col-sm" :class="[ basicInfoItem.timepicker ? 'form-group__col-5' : 'form-group__col-10']">
									<p>{{ singleActionData[basicInfoItem.id] || '-' }}</p>
								</div>
								<div class="form-group__col-5 form-group__col-sm" v-if="basicInfoItem.timepicker">
									<p>{{ singleActionData[basicInfoItem.id.replace('date', 'time')] || '-' }}</p>
								</div>
							</div>

							<p v-else>{{ singleActionData[basicInfoItem.id] || '-' }}</p>
						</div>
					</div>

					<hr 
						class="gray_hr" :key="bInfoIndex+'-hr'" v-if="basicInfoItem.hr" 
						style="width: calc(100% - 20px);margin-left: 10px;margin-top: 5px;"
					>
				</template>
			</div>
			<div id="related_fields" class="tab-content__item" :class="isActiveTab('related_fields')" v-if="singleActionData.related && singleActionData.related.length">
				<div class="addition" style="padding-top: 20px;">
					<div class="addition__header">
						<div class="headline headline--sm">
							<h2 class="headline__title">{{ $translate('related-fields') }}</h2>
						</div>
					</div>
					<ul class="editable-list">
						<li class="editable-list__item" v-for="(relatedPaddockItem, riIndex) in relatedPaddocks" :key="riIndex">
							<div class="data-row">
								<ul class="data-row__list">
									<li class="data-row__item">
										{{ relatedPaddockItem.basic_info.name || '-' }}
									</li>
									<li class="data-row__item">
										{{ relatedPaddockItem.basic_info.total_area || '-' }}
									</li>
									<li class="data-row__item">
										{{ relatedPaddockItem.basic_info.fielf || '-' }}
									</li>
									<li class="data-row__item">
										{{ new Date(relatedPaddockItem.createdAt).toLocaleDateString() || '-' }}
									</li>
								</ul>
							</div>
						</li>
					</ul>
				</div>
			</div>
			<div class="tab-content__item" :class="isActiveTab('location')" v-if="activeTab === 'location'">
				<PaddocksMap :paddocks="relatedPaddocks" />
			</div>
			<div id="company_info" class="tab-content__item" :class="isActiveTab('company_info')" v-if="activeTab === 'company_info'">
				<div class="company-page">
					<!-- <div class="company-page__img">
						<img src="@/assets/img/company-img.jpg" alt="">
					</div> -->
					<div class="company-page__info">
						<h3>{{ companyData.company_name || '-' }}</h3>
						<span>{{ $translate('history-of-the-company') }}</span>
						<p>{{ companyData.history_of_company || '-' }}</p>
					</div>
					<div class="company-page__info">
						<ul>
							<li>
								<span>{{ $translate('year-of-foundation') }}</span>
								<p>{{ companyData.year_of_foundation || '-' }}</p>
							</li>
							<li>
								<span>{{ $translate('website') }}</span>
								<p>{{ companyData.website || '-' }}</p>
							</li>
							<li>
								<span>{{ $translate('location') }}</span>
								<p>{{ companyData.location || '-' }}</p>
							</li>
						</ul>
					</div>
					<div class="company-page__info">
						<h3>{{ $translate('product-&-farm-info') }}</h3>
						<ul>
							<li>
								<span>{{ $translate('bottle-production-/-year') }}</span>
								<p>{{ companyData.production_per_year || '-' }}</p>
							</li>
							<li>
								<span>{{ $translate('vineyard-area') }}</span>
								<p>{{ companyData.production_area || '-' }}</p>
							</li>
							<li>
								<span>{{ $translate('soils') }}</span>
								<p>{{ companyData.soils || '-' }}</p>
							</li>
						</ul>
					</div>
					<div class="company-page__info">
						<ul>
							<!-- <li>
								<span>Age vine</span>
								<p>from 10 to 50 years old</p>
							</li>
							<li>
								<span>Work in the garden</span>
								<p>manual; only copper sulfate is allowed</p>
							</li> -->
							<li>
								<span>{{ $translate('cellar-work') }}</span>
								<p>{{ companyData.cellar_work || '-' }}</p>
							</li>
							<li>
								<span>{{ $translate('productivity') }}</span>
								<p>{{ companyData.productivity || '-' }}</p>
							</li>
						</ul>
					</div>
					<!-- <div class="company-page__info">
						<ul>
							<li>
								<span>Stocking density</span>
								<p>1800 vines / ha (Spergola, Moscato), 3000 vines / ha (others)</p>
							</li>
							<li>
								<span>Certification</span>
								<p>Organic, ICEA (1994)</p>
							</li>
						</ul>
					</div> -->
					<div class="company-page__info inner">
						<ul>
							<li>
								<span>{{ $translate('philosophy') }}</span>
								<p>{{ companyData.philosophy || '-' }}</p>
							</li>
						</ul>
					</div>
					<div class="company-page__info">
						<ul>
							<li>
								<span>{{ $translate('vat-number') }}</span>
								<p>{{ companyData.vat || '-' }}</p>
							</li>
							<li>
								<span>{{ $translate('fiscal-code') }}</span>
								<p>{{ companyData.fiscal || '-' }}</p>
							</li>
						</ul>
					</div>
					<div class="company-page__info">
						<h3 style="margin-bottom: 0">{{ $translate('location') }}</h3>
						<div class="company-page__info" style="padding: 30px 0">
							<ul>
								<li>
									<span>{{ $translate('country') }}</span>
									<p>{{ companyData.country || '-' }}</p>
								</li>
								<li>
									<span>{{ $translate('city') }}</span>
									<p>{{ companyData.city || '-' }}</p>
								</li>
								<li>
									<span>{{ $translate('province') }}</span>
									<p>{{ companyData.province || '-' }}</p>
								</li>
							</ul>
						</div>
						<div class="map search-map" style="border-radius: var(--border-radius-7); overflow: hidden;">
							<div id="company-map" style="width: 100%; height: 410px"></div>
						</div>
					</div>
					<div class="gallery">
						<div class="gallery__item" v-for="imgItem in media" :key="imgItem.id">
							<img :src="imgItem.url" alt="">
						</div>
					</div>

				</div>
				<hr class="line-hr my-25" v-if="false">
				<div class="company-page__related-fields" v-if="false">
					<div class="headline headline--sm">
						<h2 class="headline__title">{{ $translate('related-fields') }}</h2>
					</div>
					<ul class="editable-list">
						<li class="editable-list__item">
							<div class="data-row">
								<ul class="data-row__list">
									<li class="data-row__item">Field Name 1</li>
									<li class="data-row__item">123</li>
									<li class="data-row__item">Crop Name</li>
									<li class="data-row__item">Variety Name</li>
								</ul>
							</div>
						</li>
						<li class="editable-list__item">
							<div class="data-row">
								<ul class="data-row__list">
									<li class="data-row__item">Field Name 2</li>
									<li class="data-row__item">123</li>
									<li class="data-row__item">Crop Name 2</li>
									<li class="data-row__item">Variety Name 2</li>
								</ul>
							</div>
						</li>
						<li class="editable-list__item">
							<div class="data-row">
								<ul class="data-row__list">
									<li class="data-row__item">Field Name 3</li>
									<li class="data-row__item">123</li>
									<li class="data-row__item">Crop Name 3</li>
									<li class="data-row__item">Variety Name 3</li>
								</ul>
							</div>
						</li>
					</ul>
				</div>
			</div>
		</div>
	</div>
	
	<FieldLogbookDownload v-if="downloadList" @close="closeDownloadList" @confirm="confirmDownloadList" :singleAction="singleAction" />
</div>
</template>


<script>
import Base from "@/components/base";
import FieldLogbookDownload from "@/components/popups/FieldLogbookDownload";
import { API, Storage, } from 'aws-amplify';
import { mixTables } from '@/mixins';
import ActionsList from '@/views/actionsList/ActionsList';
import actionBasicInfoConfig from '@/config/farm';
import PaddocksMap from '@/views/reports/inners/map-zone-details/PaddocksMap';
import CalendarActionsView from './inners/tabs/CalendarActionsView';

export default {
    components: {
        ...Base,
		FieldLogbookDownload,
		ActionsList,
		PaddocksMap,
		CalendarActionsView,
    },
	mixins:[mixTables, ],
    data() {
        return {
			sortField: "Date",
            filterStartDate: null,
            filterEndDate: null,
			downloadList: false,
			related: [],
			loading: true,
            source: 'farmActions',
			columns: [
				{ name: 'Number', filter: 'id' },
				{ name: 'Category', filter: 'category' },
				{ name: 'Subcategory', filter: 'subcategory' },
				{ name: 'Size of the field', filter: '', colspan: 3, class: 'w-100' },
			],
			menuOpenId: null,
			singleAction: null,
			actions: [],
			actionBasicInfoConfig: actionBasicInfoConfig.actionBasicInfoConfig,
			media: [],
			logbookView: 'list'
        };
    },
    computed: {
		activeTab: {
			get() {
				return this.$route.query.tab || 'basic_info'
			},
			set(tab) {
				this.$router.push({ query: { tab }})
				if(tab === 'company_info'){
					setTimeout(() => {
						this.initMap();
					}, 100);
				}
			}
		},
		isActiveTab() {
			return tab => tab === this.activeTab ? 'active' : ''
		},
		paddocks(){
            return this.$store.state.paddocks;
        },
		companyData(){
            return this.$store.state.companyData;
        },
		relatedPaddocks(){
            return this.paddocks.filter(item => this.singleActionData.related.includes(item.id));
        },
		farmActions(){
			return this.$store.state.farmActions;
		},
		singleActionData(){
			if(this.singleAction){
				return this.actions.filter(itemF => itemF.id === this.singleAction)[0]
			} else {
				return null
			}
		},
	},
	watch:{
		activeTab(){
			if(this.activeTab === 'company_info'){
				setTimeout(() => {
					this.initMap();
				}, 100);
			}
		}
	},
    methods: {
		changeLogbookView(){
			if(this.logbookView === 'list'){
				this.logbookView = 'calendar';
			} else {
				this.logbookView = 'list';
			}
		},
        switchTab(tab) {
            this.activeTab = tab;
        },
	    updateSortField(ev) {
            const target = ev.target;
            const value = target.value;
            this.sortField = value;
        },
       	openOptions(id) {
            if(this.menuOpenId === id){
                this.menuOpenId = null;
            } else {
                this.menuOpenId = id;
            }
        },
		openDownloadList() {
            this.downloadList = true;
        },
        closeDownloadList() {
            this.downloadList = false;
        },
        confirmDownloadList(format) {
            console.log(format);
            this.closeDownloadList();
        },
		singleActionChange(id){
			if(this.activeTab === 'company_info'){
				setTimeout(() => {
					this.initMap();
				}, 100);
			}

			if(this.singleAction === id){
				this.singleAction = null;
			} else {
				this.singleAction = id;
			}
		},
		initMap() {
			let payload = {...this.companyData.lat_lng};
			
			this.$provider.gmap.createMap('company-map', payload);
		},
		async getAttachments() {
			let requests;

			if(
				this.companyData &&
				this.companyData.media &&
				this.companyData.media.items
			) {
				requests = this.companyData.media.items.map(async item => { 
					return {
						id: item.id,
						key: item.key,
						url: await Storage.get(item.key) 
					}	
				});
				Promise.all(requests).then(responses => this.media = responses);
			}
		}
    },
	async created(){
		let farm = await this.$store.dispatch('GET_FARM');
		if(!farm){
			await API.graphql({
				query: createFarm,
				variables: {
					input: {
						companyID: this.$store.state.companyData.id
					}
				}
			});

			await this.$store.dispatch('GET_FARM');
		}
		await this.$store.dispatch('GET_PADDOCKS');
		await this.$store.dispatch('GET_DEVICES');
		await this.$store.dispatch('GET_FARMACTIONS');
		await this.getAttachments();
		this.loading = false;
	},
};
</script>