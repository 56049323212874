<template>
    <div class="report-calendar" :class="{ 'processing':loading }">
        <div class="report-calendar__date" :class="{ 'processing':loading }">
            <div class="report-calendar__sidebar" :style="{ opacity: loading ? 0 : 1}">
				<div class="report-calendar__sidebar-body">
					<div class="data-range-group">
						<div class="litepicker"></div>
					</div>
				</div>
			</div>
        </div>
        <div class="report-calendar__shedule" style="height: calc(100vh - 162px);">
            <FullCalendar ref="fullCalendar" :options="calendarOptions" id='report-shedule' class="shedule-report" style="height: calc(100vh - 133px);" v-if="!loading" />
        </div>
    </div>
</template>

<script>
import '@fullcalendar/core/vdom' // solves problem with Vite
import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from '@fullcalendar/interaction'
import Base from "@/components/base";
import Litepicker from 'litepicker';

export default {
	components: {
		FullCalendar,
		...Base,
	},
	data() {
		return {
			loading: true,
			calendarOptions: {
				plugins: [ dayGridPlugin, interactionPlugin, timeGridPlugin ],
				initialView: 'dayGridMonth',
				headerToolbar: {
					left: 'title,prev,next',
					center: 'today',
					right: 'timeGridDay,timeGridWeek,dayGridMonth',
				},
				// contentHeight: 'auto',
				height: 'parent',
				events: [],
				eventClick: function(info) {
					window.CalendarActionsView.singleActionChange(info.event.id)
				},
				defaultDate: new Date(),
			},
			sidebarPicker0: '',
			sidebarPicker1: '',
			sidebarPicker2: '',
			litepickerElement: null,
			pickerDate: null
		}
	},
	computed:{
		farmActions(){
			let returnedData = [];
			this.$store.state.farmActions.forEach(item => {
				if(item.name_of_the_operation && item.date_of_start_of_the_operation && item.time_of_start_of_the_operation) {
					let dateStartSeparated = item.date_of_start_of_the_operation.split('.');

					returnedData.push({
						id: item.id,
						title: item.name_of_the_operation,
						start:  `${dateStartSeparated[2]}-${dateStartSeparated[1]}-${dateStartSeparated[0]}T${item.time_of_start_of_the_operation}:00.212Z`,
      					allDay: false,
					})
				}
			})

			return returnedData;
		},
	},
	methods: {
		singleActionChange(id){
			this.$emit('singleActionChange', id)
		},
	},
	mounted(){
		window.CalendarActionsView = this;
		this.calendarOptions.events = this.farmActions;
		this.$emit('initedActions', this.$store.state.farmActions);
		setTimeout(() => {
			this.loading = false;
		}, 2000);

		this.litepickerElement = new Litepicker({ 
			element: document.querySelector('.litepicker'),
			numberOfMonths: 3,
			numberOfColumns: 1,
			inlineMode: true,
			// singleMode: false,
			startDate: this.pickerDate,
			setup: (picker) => {
				picker.on('selected', (date) => {
					this.pickerDate = date.dateInstance;
					let localeData = this.pickerDate.toLocaleDateString('en-GB').split('/');

					let calendarApi = this.$refs.fullCalendar.getApi();
					calendarApi.gotoDate(`${localeData[2]}-${localeData[1]}-${localeData[0]}`);
				});
			},
		});
	}
}
</script>

<style>
.litepicker {
	display: flex;
	margin-left: -5px;
}
.litepicker .container__months {
	width: 100%;
	box-shadow: none;
}
.litepicker .container__months .month-item {
	width: 100%;
	max-width: 200px;
    min-width: 200px;
}
.litepicker .container__months .month-item-header .button-previous-month, .litepicker .container__months .month-item-header .button-next-month {
	visibility: visible;
}
.litepicker .container__months .month-item:not(:first-child) .month-item-weekdays-row {
	display: none;
}
.litepicker .container__months .month-item:not(:first-child) .month-item-header button {
	display: none;
}
.litepicker .container__months .month-item:not(:first-child) .month-item-header {
	padding-bottom: 5px;
	padding-top: 5px;
}
.litepicker .container__days>div, 
.litepicker .container__days>a,
.litepicker .container__days .day-item {
	width: 28px !important;
    padding: 0 !important;
}
.litepicker .container__months .month-item-header div {
	order: 0;
	text-align: left;
	font-weight: 500;
    font-size: 16px;
    line-height: 22px;
}
.litepicker .container__months .month-item-header .button-previous-month {
	order: 1;
}
.litepicker .container__months .month-item-header .button-next-month {
	order: 2;
}
.litepicker .container__months .month-item-weekdays-row {
    background: #F6F8F9;
    width: 236px;
    padding: 0 19px;
    margin-left: -15px;
}
.litepicker .container__months .month-item-weekdays-row>div {
	font-weight: 600;
	font-size: 10px;
	line-height: 23px;
	text-align: center;
	color: #9DB0B7;
	padding: 1px 0;
	width: 28px;
    max-width: 28px;
}
.litepicker .container__months .month-item-header {
	padding-top: 0;
    padding-bottom: 20px;
    margin-top: -9px;
}
</style>