<template>
    <div>

        <TableAdvanced 
            v-model="sortCol" 
            :rows="filteredRows" 
            :columns="columns" 
            
            :isCheckedAll="isCheckedAll"
            :checkedInputs="checkedInputs"
            @toggleAllCheckedInputs="toggleAllCheckedInputs"
        >
            <template v-slot:filters>
                <div class="filter-options__items flex-auto">
                    <div class="filter-options__search">
                        <input type="search" class="input bg-left search-icon" placeholder="Search" v-model="rowsFilters.search" />
                    </div>
                </div>
                <BluePlusButton style="margin-bottom:10px;margin-left:10px" to="/reports/custom/add" >{{ $translate('create-custom-report') }}</BluePlusButton>
            </template>

            <template v-slot:row="slotData">
                <td>
                    <label class="module__check">
                        <input type="checkbox" name="product" :checked="checkedInputs[slotData.r.id]" @change="setCheckedInputs($event,slotData.r.id)"/>
                        <span class="check"></span>
                        <router-link :to="`/reports/custom/${slotData.r.id}`" class="text">
                            <span class="ml-10">{{  slotData.r.id }}</span>
                        </router-link>
                    </label>
                </td>
                <td>
                    {{ slotData.r.name }}
                </td>
                <td>
                    {{ slotData.r.date }}
                </td>
                <td>
                    {{ slotData.r.productName }}
                </td>
                <td class="text-end">
                    <div class="table-actions flex items-center justify-end">
                        <router-link :to="'/reports/custom/edit/'+slotData.r.id" class="btn btn-icon">
                            <inline-svg :src="require(`@/assets/img/ico-gear.svg`)" />
                        </router-link>
                        <button type="button" class="btn btn-icon red-color" @click="confirmDelete(slotData.r.id)">
                            <inline-svg :src="require(`@/assets/img/trash-delete.svg`)" />
                        </button>
                    </div>
                </td>
            </template>
        </TableAdvanced>
        <TableMessages
            :rows="rows"
            :filters="rowsFilters"
            title="No Reports yet!"               
            searchTitle="No Reports Corresponding To The Criteria"
        ></TableMessages>

		<Pagination v-model="filteredRows" :items="rawSource" />
</div>

</template>

<script>
import { listCategories } from "@/graphql/queries";
import { API } from 'aws-amplify';
import {
    createProductsCategory,
    deleteCategory
} from '@/graphql/mutations';

import Popups from "@/components/popups";
import Base from "@/components/base";
import Buttons from "@/components/buttons";
import { mixTables,mixDeleteItem } from "@/mixins";

export default {
    props:['id'],
    components: {
        ...Popups,
        ...Base,
        ...Buttons
    },
    mixins: [mixTables,mixDeleteItem],
    data() {
        return {
			source: "customReports",
			columns: [
                { name: "Report Number", filter: "id" },
                { name: "Report Name", filter: "name"},
				{ name: "Date", filter:"date"},
				{ name: "Products", class:"productName"},
                { name: "Actions",class:"text-end" },

            ],
        
			calculatedFields:[
            {
				filter: 'productName',
				path: ['product'],
				calcFunction: (id)=>this.products.find(p=>p.id==id)?.title || "-"
			},
            {
				filter: 'date',
				path: ['createdAt'],
				calcFunction: (date)=>date.split("T")[0].split("-").reverse().join(".")
			}
            ], 
            deleteQueryType:"deleteCustomReport",

        };
    },
    computed: {
        userData() {
            return this.$store.state.userData || {};
        },
        companyData() {
            return this.$store.state.companyData || {};
        },
        categories() {
            return this.$store.state.categories || [];
        },
        products() {
            return this.$store.state.products || [];
        },
      
        // sourceData(){
        //     return [
        //         {id:'sxad-31-ss',name:"custom 1",date:"23.05.2022",product:"product1"},
        //         {id:'scbad-31-ssda',name:"custom 2",date:"24.05.2022",product:"product2"},
        //         {id:'sgad-31-ss',name:"custom 3",date:"01.07.2024",product:"product3"},
        //         {id:'svgad-31-ss',name:"custom 4",date:"03.01.2022",product:"product4"}
        //     ]
        // }
    },
    methods: {
     
    },
};
</script>
<style scoped>
    /* .filter-options-wrapper {
        justify-content: space-between;
        align-items: center;
    } */
</style>