<template>
<div class="wrapper_main">
	<main class="content">
		<template >
			<div class="content__header">
				<div class="content__header-left">
					<router-link to="/reports" class="btn btn-w-shadow btn-sm btn-back">
						<inline-svg :src="require(`@/assets/img/Arrow-prev.svg`)"/>
					</router-link>
					<div class="flex items-center">
						<span class="content__header-icon">
							<inline-svg :src="require(`@/assets/img/ico-file-chart.svg`)"/>
						</span>
						<h1 class="heading-page-h1">{{ reportCategoriesList.find(item => item.category === this.$route.params.category).title }}</h1>
					</div>
				</div>
			</div>
			<component :is="isReport" v-if="isReport !== 'empty'" />
			<div style="padding-top: 20px;" v-else>
				<h2>{{ $translate('this-report-is-empty') }}</h2>
			</div>
		</template>
	</main>
</div>
</template>

<script>
import IOTSensorsReport from "./inners/iot-sensors/IOTSensorsReport";
import MapZoneDetailsReport from "./inners/map-zone-details/MapZoneDetailsReport.vue";
import TreatmentRecordsReports from "./inners/actions/TreatmentRecordsReports.vue";
import FieldLogbook from "./FieldLogbook.vue";
import { reportCategoriesList } from '@/config/farm';
import EDXTable from "./inners/edxanalysis/EDXTable";
import CustomReports from "./inners/custom/CustomReports";

export default {
    components: {
        'treatments-action-details': TreatmentRecordsReports,
        'iot-sensors': IOTSensorsReport,
        'zone-details': MapZoneDetailsReport,
        'field-logbook': FieldLogbook,
		'edx-analysis':EDXTable,
		'custom':CustomReports
	},
    data() {
        return {
			reportCategoriesList: reportCategoriesList,
			haveComponents: [
				'treatments-action-details',
				'iot-sensors',
				'zone-details',
				'field-logbook',
				'edx-analysis',
				'custom',
			]
        };
    },
    computed:{
        isReport(){
            if(this.reportCategoriesList.find(item => item.category === this.$route.params.category).details){
                return 'zone-details'
            } else {
				if(this.haveComponents.includes(this.$route.params.category)){
					return this.$route.params.category;
				} else {
					return 'empty';
				}
            }
        }
    }
}
</script>