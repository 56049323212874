<template>
	<div :class="{ 'processing':loading }" style="min-height: 300px;">
		<div class="tabs-wrapper" v-show="actions.length">
			<ul class="tabs" :style="{ opacity: loading ? 0 : 1 }">
				<li class="tabs__item" v-for="(item, index) in tabs" :key="index">
					<a class="nav-tab swith-content-btn " @click="switchTab(item.type)" :class="isActiveTab(item.type)">
						{{ item.title }}
					</a>
				</li>
			</ul> 
		</div>
		<div class="tab-content" v-if="!loading">
			<div class="tab-content__item" :class="isActiveTab(tabs[0].type)" v-show="actions.length">
                <ActionsList :innerTab="true" subcategoryFilter="Treatment" @initedActions="actions = $event" @singleActionChange="singleActionChange($event)" v-if="!singleAction && !singleActionData" />
				<div class="form-group form-group--wrapper" style="padding-top: 15px;" v-else>
					<a @click="singleActionChange(null)" class="btn btn-w-shadow btn-sm btn-back">
						<inline-svg :src="require(`@/assets/img/Arrow-prev.svg`)"/> {{ $translate('full-list-actions') }}
					</a>
					<div class="addition__group" style="padding-top: 15px;">
						<div class="form-group__row pointer-events-none">
							<template v-for="(basicInfoItem, bInfoIndex) in actionBasicInfoConfig[singleActionData.category]">
								<div 
									:class="[ basicInfoItem.checkboxes || basicInfoItem.textarea ? 'form-group__col-10' : 'form-group__col-5 mb-15', { 'mb-15':basicInfoItem.textarea } ]" 
									:key="bInfoIndex" v-if="!basicInfoItem.time"
								>
									<div class="el-form">
										<div class="headline headline--sm" v-if="basicInfoItem.name && basicInfoItem.headlineTitle">
											<h2 class="headline__title">{{ basicInfoItem.name }}</h2>
										</div>
										<label class="el-form__title" v-if="basicInfoItem.name && !basicInfoItem.headlineTitle" style="color: #9DB0B7;">{{ basicInfoItem.name }}</label>

										<div class="form-group__row form-group__row--sm" v-if="basicInfoItem.datepicker">
											<div class="form-group__col-sm" :class="[ basicInfoItem.timepicker ? 'form-group__col-5' : 'form-group__col-10']">
												<p>{{ singleActionData[basicInfoItem.id] }}</p>
											</div>
											<div class="form-group__col-5 form-group__col-sm" v-if="basicInfoItem.timepicker">
												<p>{{ singleActionData[basicInfoItem.id.replace('date', 'time')] }}</p>
											</div>
										</div>

										<p v-else>{{ singleActionData[basicInfoItem.id] }}</p>
									</div>
								</div>

								<hr 
									class="gray_hr" :key="bInfoIndex+'-hr'" v-if="basicInfoItem.hr" 
									style="width: calc(100% - 20px);margin-left: 10px;margin-top: 5px;"
								>
							</template>
						</div>
					</div>
				</div>
			</div>
			<div class="tab-content__item" :class="isActiveTab(tabs[1].type)" v-if="activeTab === tabs[1].type">
                <PaddocksMap :paddocks="paddocks" />
			</div>
			<div class="tab-content__item active" v-show="emptyData && !actions.length && !loading">
				<h2 style="padding-top: 20px;">{{ reportCategoriesList.find(item => item.category === this.$route.params.category).title }} {{ $translate('not-found') }}</h2>
			</div>
		</div>
	</div>
</template>


<script>
import Base from "@/components/base";
import Popups from "@/components/popups";
import { mixTables } from '@/mixins';
import PaddocksMap from '@/views/reports/inners/map-zone-details/PaddocksMap';
import ActionsList from '@/views/actionsList/ActionsList';
import actionBasicInfoConfig from '@/config/farm';
import { reportCategoriesList } from '@/config/farm';

export default {
    components: {
        ...Base,
		...Popups,
		PaddocksMap,
        ActionsList,
    },
	mixins:[mixTables, ],
    data() {
        return {
			loading: true,
			reportCategoriesList: reportCategoriesList,
			tabs: [
				{
					title: 'List of Actions',
					type: 'list-of-actions',
				},

				{
					title: 'Map',
					type: 'map',
				},
			],
            actions: [],
			singleAction: null,
			actionBasicInfoConfig: actionBasicInfoConfig.actionBasicInfoConfig,
			emptyData: false,
        };
    },
    computed: {
		activeTab: {
			get() {
				return this.$route.query.tab || this.tabs[0].type
			},
			set(tab) {
				this.$router.push({ query: { tab }})
			}
		},
		isActiveTab() {
			return tab => tab === this.activeTab ? 'active' : ''
		},
        paddocks(){
            let relatedPaddocks = [];
			if(this.singleAction) {
				this.actions.filter(itemF => itemF.id === this.singleAction).map(item => item.related).forEach(element => {
					element.forEach(id => {
						relatedPaddocks.push(id);
					})
				});
			} else {
				this.actions.map(item => item.related).forEach(element => {
					element.forEach(id => {
						relatedPaddocks.push(id);
					})
				});
			}

            return this.$store.state.paddocks.filter(item => relatedPaddocks.includes(item.id));
        },
		singleActionData(){
			if(this.singleAction){
				return this.actions.filter(itemF => itemF.id === this.singleAction)[0]
			} else {
				return null
			}
		}
	},
    methods: {
		switchTab(tab) {
            this.activeTab = tab;
        },
		singleActionChange(id){
			if(this.singleAction === id){
				this.singleAction = null;
			} else {
				this.singleAction = id;
			}
		}
    },
	async created(){
		let farm = await this.$store.dispatch('GET_FARM');
		if(!farm){
			await API.graphql({
				query: createFarm,
				variables: {
					input: {
						companyID: this.$store.state.companyData.id
					}
				}
			});

			await this.$store.dispatch('GET_FARM');
		}
		await this.$store.dispatch('GET_PADDOCKS');
		await this.$store.dispatch('GET_DEVICES');

		setTimeout(() => {
			if(!this.actions.length && !this.loading) {
				this.emptyData = true;
				this.isActiveTab = 'empty';
			}
		}, 2000);

		this.loading = false;
	}
};
</script>
