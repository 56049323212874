<template>
<div :class="{ 'processing':loading }" style="min-height: 300px;">
    <TableAdvanced v-if="!loading" v-model="sortCol" :rows="filteredRows" :columns="columns" :isCheckedAll="isCheckedAll" :checkedInputs="checkedInputs" @toggleAllCheckedInputs="toggleAllCheckedInputs">
        <template v-slot:filters>
            <div class="filter-options__search">
                <input type="search" class="input bg-left search-icon" placeholder="Search" v-model="search" @input="initTables" />
            </div>
            <div class="filter-options__items flex-x-wrap flex-auto">
                <div>
                    <label class="el-form__title select-title" style="display: block;">{{ $translate('date-creation-(from)') }}</label>
                    <DatePicker v-model="filterStartDate" dateFormat="MM.dd.yyyy" :canClear="true" />
                </div>
                <div>
                    <label class="el-form__title select-title" style="display: block;">{{ $translate('date-creation-(to)') }}</label>
                    <DatePicker v-model="filterEndDate" dateFormat="MM.dd.yyyy" :canClear="true" />
                </div>
            </div>
            <div class="flex items-center">
                <span class="select-title">{{ $translate('sort-by') }}</span>
                <Dropdown
                    :options="['Date of creation', 'Name',]"
                    :current="sortField"
                    name="sortFeild"
                    @change="updateSortField"
                />
                <button type="button" class="btn btn-sm btn-w-shadow swith-content size-40-40" @click="$emit('openDownloadList')">
                    <inline-svg :src="require(`@/assets/img/ico-download.svg`)"/>
                </button>
            </div>
        </template>

        <template v-slot:row="slotData">
            <td>
                <label class="module__check">
                    <input type="checkbox" name="category" :checked="checkedInputs[slotData.r.id]" @change="setCheckedInputs($event,slotData.r.id)"/>
                    <span class="check"></span>
                    <span class="text" @click.self.stop="$emit('selectSingleZone', slotData.r.id)">{{ slotData.r.basic_info.name }}</span>
                </label>
            </td>
            <td> {{ getFirstDeviceName(slotData.r.id) ? getFirstDeviceName(slotData.r.id).name : '-' }}</td>
            <td> {{ slotData.r.basic_info.total_area || '-' }}</td>
            <td> {{ slotData.r.basic_info.grazeable_or_arable_area || '-' }} </td>
            <td> {{ slotData.r.basic_info.land_use || '-' }} </td>
            <td> {{ slotData.r.basic_info.crop_type || '-' }} </td>
            <td> {{ slotData.r.basic_info.colour_on_map }} </td>
            <td class="position-stiky">
                <div class="table-options-drop dropdown-wrapper">
                    <button
                        type="button"
                        class="btn btn-sm btn-transp dropdown-btn"
                        @click="openOptions(slotData.r.id)" :class="{ 'active': menuOpenId === slotData.r.id }"
                    >
                        <inline-svg :src="require(`@/assets/img/dots-menu.svg`)"/>
                    </button>
                    <div class="categories__dropdown dropdown-content" :class="{ 'active': menuOpenId === slotData.r.id }">
                        <ul>
                            <li>
                                <a>{{ $translate('edit') }}</a>
                            </li>
                            <li>
                                <button type="button" class="red-color">
                                    {{ $translate('delete') }}
                                </button>
                            </li>
                        </ul>
                    </div>
                </div>
            </td>
        </template>	
    </TableAdvanced>
</div>
</template>


<script>
import Base from "@/components/base";
import Popups from "@/components/popups";
import { API } from 'aws-amplify';
import { mixTables } from '@/mixins';

export default {
    components: {
        ...Base,
		...Popups
    },
	mixins:[mixTables, ],
    props: ['singleZone'],
    data() {
        return {
			loading: true,
			search: "",
			sortField: "Date of creation",
            filterStartDate: null,
            filterEndDate: null,
            source: 'paddocks',
			columns: [
				{ name: 'Name', filter: 'name' },
				{ name: 'IOT Device', filter: '' },
				{ name: 'Total Area', filter: 'total_area' },
				{ name: 'Grazeable Area', filter: 'grazeable_or_arable_area' },
				{ name: 'Land Use', filter: 'land_use' },
				{ name: 'Crop Type', filter: 'crop_type' },
				{ name: 'Colour on Map', filter: 'colour_on_map', colspan: 2 }
			],
			menuOpenId: null,
			paddocksLocale: [],
        };
    },
    computed: {
		paddockList(){
            if(this.singleZone){
				return this.$store.state.paddocks.filter(item => item.id === this.singleZone);
			} else {
				return this.$store.state.paddocks;
			}
        },
		devices(){
            return this.$store.state.devices;
        },
        sourceData(){
            return this.paddocksLocale.filter(item => (item.type === this.$route.params.category) && item.basic_info.name.toLowerCase().includes(this.search.toLowerCase()));
        }
	},
	watch: {
        filterStartDate(){
            this.filtersDateChange();
        },
        filterEndDate(){
            this.filtersDateChange();
        },
        filterTime(){
            this.filtersDateChange();
        },
    },
    methods: {
		getFirstDeviceName(itemId){
            return this.devices.find(item => item.paddockID === itemId);
        },
        updateSortField(ev, forceupdate = false) {
            let target = null;
            let value = null;
            if(!forceupdate){
                target = ev.target;
                value = target.value; 
            } else {
                target = null;
                value = ev;
            }

            this.sortField = value;

            this.paddocksLocale.sort(function(a, b){
                if(value === 'Name'){
                    if(a.basic_info.name.toLowerCase() < b.basic_info.name.toLowerCase()) { return -1; }
                    if(a.basic_info.name.toLowerCase() > b.basic_info.name.toLowerCase()) { return 1; }
                }

                if(value === 'Date of creation'){
                    if(a.createdAt < b.createdAt) { return -1; }
                    if(a.createdAt > b.createdAt) { return 1; }
                }

                return 0;
            });
            this.initTables();
        },
		filtersDateChange(){
            if(this.filterStartDate && this.filterEndDate){
                this.paddocksLocale = [...this.paddockList].filter(item => new Date(item.createdAt) >= new Date(`${this.filterStartDate}, ${this.filterTime}`) 
                    && new Date(item.createdAt) <= new Date(`${this.filterEndDate}, ${this.filterTime}`));
            }

            if(this.filterStartDate && !this.filterEndDate) {
                this.paddocksLocale = [...this.paddockList].filter(item => new Date(item.createdAt) >= new Date(`${this.filterStartDate}, ${this.filterTime}`));
            }

            if(this.filterEndDate && !this.filterStartDate) {
                this.paddocksLocale = [...this.paddockList].filter(item => new Date(item.createdAt) <= new Date(`${this.filterEndDate}, ${this.filterTime}`));
            }

            if(!this.filterStartDate && !this.filterEndDate) {
                this.paddocksLocale = [...this.paddockList];
            }

            this.updateSortField(this.sortField, true);
            this.initTables();
        },
       	openOptions(id) {
            if(this.menuOpenId === id){
                this.menuOpenId = null;
            } else {
                this.menuOpenId = id;
            }
        },
    },
	async created(){
		this.paddocksLocale = [...this.paddockList];
		this.initTables();
		this.loading = false;
	}
};
</script>