<template>
    <PopupWrpr @close="close" class="danger-message fieldlogbook_print_popup" :class="{'popup_to_print':toPrint}">
        <div class="popup__window-content" :style="`max-width: 1500px;width: ${ widthToPDF ? '1500px' : '100%' }`" :class="{ 'processing':loading }">
            <div class="popup__window-header mb-30" v-if="!toPrint">
                <h2>{{ $translate('field-logbook-report') }}</h2>
                <div class="flex items-center">
                    <a class="btn btn-primary btn-standart-2" @click="exportPDF" style="margin-right: 20px;">
                        <span>{{ widthToPDF ? $translate('in-the-process...') : $translate('export') }}</span>
                    </a>
                    <button type="button" class="popup__closer" @click="close">
                        <inline-svg :src="require('@/assets/img/close-icon.svg')" />
                    </button>
                </div>
              
            </div>
            <div class="popup__window-body fieldlogbook_print_content" id="fieldlogbook_print_content">
                <PaddocksMap :paddocks="paddocks" :print="true" mapHeightProps="450px" />
                <hr class="gray_hr" style="margin-top: 20px;">
                <div v-if="!singleAction">
                    <h3>{{ $translate('actions-list') }}</h3>
                    <ActionsList :innerTab="true" :fieldBookList="true" :print="true" />
                    <hr class="gray_hr">
                    <h3>{{ $translate('company-data') }}</h3>
                    <div class="company-page">
                        <div class="company-page__info">
                            <h3>{{ companyData.company_name || '-' }}</h3>
                            <span>{{ $translate('history-of-the-company') }}</span>
                            <p>{{ companyData.history_of_company || '-' }}</p>
                        </div>
                        <div class="company-page__info">
                            <ul>
                                <li>
                                    <span>{{ $translate('year-of-foundation') }}</span>
                                    <p>{{ companyData.year_of_foundation || '-' }}</p>
                                </li>
                                <li>
                                    <span>{{ $translate('website') }}</span>
                                    <p>{{ companyData.website || '-' }}</p>
                                </li>
                                <li>
                                    <span>{{ $translate('location') }}</span>
                                    <p>{{ companyData.location || '-' }}</p>
                                </li>
                            </ul>
                        </div>
                        <div class="company-page__info">
                            <h3>{{ $translate('product-&-farm-info') }}</h3>
                            <ul>
                                <li>
                                    <span>{{ $translate('bottle-production-/-year') }}</span>
                                    <p>{{ companyData.production_per_year || '-' }}</p>
                                </li>
                                <li>
                                    <span>{{ $translate('vineyard-area') }}</span>
                                    <p>{{ companyData.production_area || '-' }}</p>
                                </li>
                                <li>
                                    <span>{{ $translate('soils') }}</span>
                                    <p>{{ companyData.soils || '-' }}</p>
                                </li>
                            </ul>
                        </div>
                        <div class="company-page__info">
                            <ul>
                                <li>
                                    <span>{{ $translate('cellar-work') }}</span>
                                    <p>{{ companyData.cellar_work || '-' }}</p>
                                </li>
                                <li>
                                    <span>{{ $translate('productivity') }}</span>
                                    <p>{{ companyData.productivity || '-' }}</p>
                                </li>
                            </ul>
                        </div>
                        <div class="company-page__info inner">
                            <ul>
                                <li>
                                    <span>{{ $translate('philosophy') }}</span>
                                    <p>{{ companyData.philosophy || '-' }}</p>
                                </li>
                            </ul>
                        </div>
                        <div class="company-page__info">
                            <ul>
                                <li>
                                    <span>{{ $translate('vat-number') }}</span>
                                    <p>{{ companyData.vat || '-' }}</p>
                                </li>
                                <li>
                                    <span>{{ $translate('fiscal-code') }}</span>
                                    <p>{{ companyData.fiscal || '-' }}</p>
                                </li>
                            </ul>
                        </div>
                        <div class="company-page__info">
                            <h3 style="margin-bottom: 0">{{ $translate('location') }}</h3>
                            <div class="company-page__info" style="padding: 30px 0 0;border-bottom: 0;">
                                <ul>
                                    <li>
                                        <span>{{ $translate('country') }}</span>
                                        <p>{{ companyData.country || '-' }}</p>
                                    </li>
                                    <li>
                                        <span>{{ $translate('city') }}</span>
                                        <p>{{ companyData.city || '-' }}</p>
                                    </li>
                                    <li>
                                        <span>{{ $translate('province') }}</span>
                                        <p>{{ companyData.province || '-' }}</p>
                                    </li>
                                </ul>
                            </div>
                            <!-- <div class="map search-map" style="border-radius: var(--border-radius-7); overflow: hidden;">
                                <div id="company-map" style="width: 100%; height: 410px"></div>
                            </div> -->
                        </div>
                        <!-- <div class="gallery">
                            <div class="gallery__item" v-for="imgItem in media" :key="imgItem.id">
                                <img :src="imgItem.url" alt="">
                            </div>
                        </div> -->
                    </div>
                </div>
                <div v-else>
                    <h3>{{ $translate('actions-details') }}</h3>
                    <div class="addition__group" style="padding-top: 15px;">
                        <div class="form-group__row pointer-events-none">
                            <template v-for="(basicInfoItem, bInfoIndex) in actionBasicInfoConfig[singleActionData.category]">
                                <div 
                                    :class="[ basicInfoItem.checkboxes || basicInfoItem.textarea ? 'form-group__col-10' : 'form-group__col-5 mb-15', { 'mb-15':basicInfoItem.textarea } ]" 
                                    :key="bInfoIndex" v-if="!basicInfoItem.time"
                                >
                                    <div class="el-form">
                                        <div class="headline headline--sm" v-if="basicInfoItem.name && basicInfoItem.headlineTitle">
                                            <h2 class="headline__title">{{ basicInfoItem.name }}</h2>
                                        </div>
                                        <label class="el-form__title" v-if="basicInfoItem.name && !basicInfoItem.headlineTitle" style="color: #9DB0B7;">{{ basicInfoItem.name }}</label>

                                        <div class="form-group__row form-group__row--sm" v-if="basicInfoItem.datepicker">
                                            <div class="form-group__col-sm" :class="[ basicInfoItem.timepicker ? 'form-group__col-5' : 'form-group__col-10']">
                                                <p>{{ singleActionData[basicInfoItem.id] }}</p>
                                            </div>
                                            <div class="form-group__col-5 form-group__col-sm" v-if="basicInfoItem.timepicker">
                                                <p>{{ singleActionData[basicInfoItem.id.replace('date', 'time')] }}</p>
                                            </div>
                                        </div>

                                        <p v-else>{{ singleActionData[basicInfoItem.id] }}</p>
                                    </div>
                                </div>

                                <hr 
                                    class="gray_hr" :key="bInfoIndex+'-hr'" v-if="basicInfoItem.hr" 
                                    style="width: calc(100% - 20px);margin-left: 10px;margin-top: 5px;"
                                >
                            </template>
                        </div>
                    </div>
                    <hr class="gray_hr">
                    <h3 v-if="relatedFields.length">{{ $translate('related-fields') }}</h3>
                    <div class="table-wrapper" v-if="relatedFields.length">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th>{{ $translate('name') }}</th>
                                    <th>{{ $translate('iot-device') }}</th>
                                    <th>{{ $translate('total-area') }}</th>
                                    <th>{{ $translate('grazeable-area') }}</th>
                                    <th>{{ $translate('land-use') }}</th>
                                    <th>{{ $translate('crop-type') }}</th>
                                    <th>{{ $translate('colour-on-map') }}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="paddockItem in relatedFields" :key="paddockItem.id">
                                    <td>{{ paddockItem.basic_info.name }}</td>
                                    <td>{{ getFirstDeviceName(paddockItem.id) ? getFirstDeviceName(paddockItem.id).name : '-' }}</td>
                                    <td>{{ paddockItem.basic_info.total_area || '-' }}</td>
                                    <td>{{ paddockItem.basic_info.grazeable_or_arable_area || '-' }} </td>
                                    <td>{{ paddockItem.basic_info.land_use || '-' }} </td>
                                    <td>{{ paddockItem.basic_info.crop_type || '-' }} </td>
                                    <td>{{ paddockItem.basic_info.colour_on_map }} </td>
                                </tr>               
                            </tbody>
                        </table>
                    </div>

                    <h3 v-if="relatedHerds.length">{{ $translate('related-herds') }}</h3>
                    <div class="table-wrapper" v-if="relatedHerds.length">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th>{{ $translate('name') }}</th>
                                    <th>{{ $translate('iot-device') }}</th>
                                    <th>{{ $translate('type') }}</th>
                                    <th>{{ $translate('age-class') }}</th>
                                    <th>{{ $translate('date-of-birth') }}</th>
                                    <th>{{ $translate('origin') }}</th>
                                    <th>{{ $translate('rfid-number') }}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="paddockItem in relatedHerds" :key="paddockItem.id">
                                    <td>{{ paddockItem.basic_info.name }}</td>
                                    <td>{{ getFirstDeviceName(paddockItem.id) ? getFirstDeviceName(paddockItem.id).name : '-' }}</td>
                                    <td>{{ paddockItem.basic_info.type || '-' }}</td>
                                    <td>{{ paddockItem.basic_info.age_class || '-' }} </td>
                                    <td>{{ paddockItem.basic_info.date_of_birth || '-' }} </td>
                                    <td>{{ paddockItem.basic_info.origin || '-' }} </td>
                                    <td>{{ paddockItem.basic_info.rfid_number }} </td>
                                </tr>               
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div id="editor"></div>
            <div class="popup__window-actions" v-if="!toPrint">
                <button type="button" class="btn btn-w-shadow btn-standart-2 popup__closer" @click="close">
                    <span>{{ $translate('cancel') }}</span>
                </button>
                <button type="button" class="btn btn-primary btn-standart-2" @click="exportPDF">
                    <span>{{ widthToPDF ? $translate('in-the-process...') : $translate('export') }}</span>
                </button>
            </div>
        </div>
    </PopupWrpr>
</template>

<script>
import Base from "@/components/base";
import PaddocksMap from '@/views/reports/inners/map-zone-details/PaddocksMap';
import ActionsList from '@/views/actionsList/ActionsList';
import { API, Storage, } from 'aws-amplify';
import actionBasicInfoConfig from '@/config/farm';
import PopupWrpr from './PopupWrpr';

import html2canvas from 'html2canvas';
import { jsPDF } from "jspdf";

export default {
    name: "FarmListDownload",
    components: {
        ...Base,
        PaddocksMap,
        ActionsList,
        PopupWrpr,
    },
    props: ['singleAction'],
    data() {
        return {
            loading: true,
            format:"PDF",
            toPrint: false,
            media: [],
            actionBasicInfoConfig: actionBasicInfoConfig.actionBasicInfoConfig,
            widthToPDF: false,
        };
    },
    computed:{
        farmActions(){
			return this.$store.state.farmActions;
		},
        paddocks(){
            let relatedPaddocks = [];
			if(this.singleAction) {
				this.farmActions.filter(itemF => itemF.id === this.singleAction).map(item => item.related).forEach(element => {
					element.forEach(id => {
						relatedPaddocks.push(id);
					})
				});
			} else {
				this.farmActions.map(item => item.related).forEach(element => {
					element.forEach(id => {
						relatedPaddocks.push(id);
					})
				});
			}

            return this.$store.state.paddocks.filter(item => relatedPaddocks.includes(item.id));
        },
        devices(){
            return this.$store.state.devices;
        },
        companyData(){
            return this.$store.state.companyData;
        },
        singleActionData(){
			if(this.singleAction){
				return this.farmActions.filter(itemF => itemF.id === this.singleAction)[0]
			} else {
				return null
			}
		},
        relatedFields(){
            return this.paddocks.filter(item => item.type === 'field');
        },
        relatedHerds(){
            return this.paddocks.filter(item => item.type === 'herd');
        }
    },
    methods: {
        close() {
            this.$emit("close");
        },
        timeConverter(UNIX_timestamp){
            var a = new Date(UNIX_timestamp * 1000);
            var months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
            var year = a.getFullYear();
            var month = months[a.getMonth()];
            var date = a.getDate();
            var hour = a.getHours();
            var min = a.getMinutes();
            var sec = a.getSeconds();
            var time = date + ' ' + month + ' ' + year + ' ' + hour + ':' + min + ':' + sec ;
            return time;
        },
        exportPDF() {
            this.widthToPDF = true;
            setTimeout(() => {
                // window.print();

                var ignoreElements = function(node) {
                    return node.nodeName === 'IFRAME';
                }

                html2canvas(document.querySelector("#fieldlogbook_print_content"), {
                    useCORS: true,
                    allowTaint: false,
                    ignoreElements: (node) => {
                        return node.nodeName === 'IFRAME';
                    }
                }).then(canvas => {
                    // document.body.appendChild(canvas)
                    var myImage = canvas.toDataURL("image/jpeg,1.0");
                    // Adjust width and height
                    var imgWidth =  (2200 * 21) / 240;
                    var imgHeight = (3000 * 21) / 240;
                    // jspdf changes
                    var pdf = new jsPDF('p', 'mm', 'a4');
                    pdf.addImage(myImage, 'png', 10, 7, imgWidth, imgHeight); // 2: 19
                    pdf.save(`field-logbook-${this.timeConverter(Date.now())}.pdf`);
                    console.log('printed');
                    this.widthToPDF = false;
                });

            }, 1000);
        },
        updateFormatField(ev) {
            const target = ev.target;
            const value = target.value;
            this.format = value;
        },
        // async getAttachments() {
		// 	let requests;

		// 	if(
		// 		this.companyData &&
		// 		this.companyData.media &&
		// 		this.companyData.media.items
		// 	) {
		// 		requests = this.companyData.media.items.map(async item => { 
		// 			return {
		// 				id: item.id,
		// 				key: item.key,
		// 				url: await Storage.get(item.key) 
		// 			}	
		// 		});
		// 		Promise.all(requests).then(responses => this.media = responses);
		// 	}
		// },
        // initMap() {
		// 	let payload = {...this.companyData.lat_lng};
			
		// 	this.$provider.gmap.createMap('company-map', payload);
		// },
        getFirstDeviceName(itemId){
            return this.devices.find(item => item.paddockID === itemId);
        },
    },
    async created(){
        // await this.getAttachments();
        this.loading = false;
        // setTimeout(() => {
        //     this.initMap();
        // }, 300);
    },
};
</script>

<style>
.popup_wrpr.popup_to_print .popup__window {
    margin: 0;
    padding: 0;
}
.popup_wrpr.popup_to_print .popup__window .popup__window-content {
    max-width: 100vw;
    width: 100vw;
    height: 100vh;
    border-radius: 0;
    box-shadow: none;
}
.popup__closer:hover {
    opacity: 0.7;
    background: #f3f3f3;
    border-radius: 5px;
}
</style>